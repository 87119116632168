<template>
  <v-card>
    <v-toolbar flat>
      <v-row>
        <v-col cols="4">
          <DateInput v-model="activationDate" />
        </v-col>
        <v-col cols="4">
          <TimeInput v-model="activationTime" />
        </v-col>
        <v-col cols="4">
          <v-btn
            text
            @click="set"
            :disabled="!activationDate || !activationTime"
            >für alle sichtbaren setzen</v-btn
          >
        </v-col>
      </v-row>
    </v-toolbar>
    <v-divider />
    <v-data-table
      :items="schoolClassesFiltered"
      :headers="headers"
      :loading="loading"
      :items-per-page="-1"
      hide-default-footer
      sort-by="code"
    >
      <template v-slot:item.activationDate="{ item }">
        <v-chip
          v-if="!item.edit && item.activationDate"
          @click="$set(item, 'edit', true)"
        >
          <DateValue v-model="item.activationDate" />
        </v-chip>
        <DateInput v-model="item.activationDate" v-else />
      </template>
      <template v-slot:item.activationTime="{ item }">
        <v-chip
          v-if="!item.edit && item.activationTime"
          @click="$set(item, 'edit', true)"
        >
          {{ item.activationTime ? item.activationTime : "–" }}
        </v-chip>
        <TimeInput v-model="item.activationTime" v-else />
      </template>
    </v-data-table>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <v-btn @click="fetchData" text>abbruch</v-btn>

      <v-btn @click="save" text color="error">speichern</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import DateValue from "common/components/DateValue.vue";
import DateInput from "common/components/DateInput.vue";
import TimeInput from "common/components/TimeInput.vue";
export default {
  name: "OnBoardingSchoolClasses",
  components: { DateValue, DateInput, TimeInput },
  props: ["search"],
  data() {
    return {
      loading: false,
      saving: false,
      activationDate: "",
      activationTime: "",
      data: {},
      headers: [
        {
          text: "Klasse",
          value: "code",
        },
        {
          text: "Datum",
          value: "activationDate",
        },
        {
          text: "Zeit",
          value: "activationTime",
        },
      ],
    };
  },
  computed: {
    schoolClassesFiltered() {
      if (!this.search) {
        return this.data.schoolClasses;
      }
      return this.data.schoolClasses?.filter((el) =>
        el.code.toLowerCase().includes(this.search?.toLowerCase())
      );
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.data = await this.apiList({
        resource: "onboarding/admin",
      });
      this.loading = false;
    },
    async save() {
      this.saving = true;
      await this.apiPost({
        resource: "onboarding/admin",
        data: this.data,
      });
      this.saving = false;
      this.fetchData();
    },
    async set() {
      for (const item of this.schoolClassesFiltered) {
        item.activationDate = this.activationDate;
        item.activationTime = this.activationTime;
      }
    },
  },
  async created() {
    this.fetchData();
  },
};
</script>
